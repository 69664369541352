<template>
    <div class="container">
        <div class="header">
            <div class="header__wrap">
                <router-link class="header__logo" to="/">GELLERT LERA</router-link>
                <div class="header__links">
                    <router-link v-for="(item, index) in this.navlinks" :key="index" class="links__item a" :to="item.path">{{item.name}}</router-link>

                    <div>
                        <router-link v-for="(item, index) in this.f" :key="index" class="links__item b" :to="item.path">{{item.name}}</router-link>
                    </div>
                    <div>
                        <router-link v-for="(item, index) in this.s" :key="index" class="links__item b" :to="item.path">{{item.name}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import action from '../services/main.js'

export default {
    name: 'Header',
    data() {
        return {
            navlinks: [],
            f: [],
            s: []
        }
    },
    mounted() {
        action.getNavLinks().then(res => {
            this.navlinks = res.data

            for (let i = 0; i < this.navlinks.length; i++) {
                if(i < 3) this.f.push(this.navlinks[i]);
                else this.s.push(this.navlinks[i]);
            }
        })
    }
}
</script>

<style scoped>
.header {
    padding: 0 20px;
}
.header__wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 15px 15px;
}
.header__logo {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.3em;
    color: #1E1E1E;

    text-decoration: none;
}
.header__links {
    display: flex;
    gap: 30px;
}
.links__item {
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
}
.links__item:hover {
    color: #FF8B8B;
}
.b {
    display: none;
}

@media screen and (max-width: 768px) {
    .header__wrap {
        flex-direction: column;
    }
    .links__item {
        text-align: center;
    }
    .a {
        display: none;
    }
    .header__links {
        justify-content: center
    }
    .b {
        display: block;
        margin-bottom: 10px;
    }
}
</style>