import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/music',
        name: 'Music',
        component: () => import('../views/Music.vue')
    },
    {
        path: '/video',
        name: 'Video',
        component: () => import('../views/Video.vue')
    },
    {
        path: '/information',
        name: 'Information',
        component: () => import('../views/Information.vue')
    },
    {
        path: '/offer',
        name: 'Offer',
        component: () => import('../views/Offer.vue')
    },
    {
        path: '/offer_author',
        name: 'OfferAuthor',
        component: () => import('../views/Offer.vue')
    },
    {
        path: '/admin',
        name: 'AdminPanel',
        component: () => import('../views/AdminPanel.vue')
    },
    {
        path: '/admin_registration',
        name: 'AdminRegistration',
        component: () => import('../views/AdminRegistration.vue')
    }
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
