import axios from 'axios'

const API_URL = 'https://gellert-lera.ru/api/'
// const API_URL = 'http://localhost:80/api/'

const getMusic = (status, view, s) => {
    if(!status) {
        return axios.get(API_URL + 'music.get?view=' + view)
    }else {
        let token = localStorage.getItem('token');
        if(s) {
            return axios.get(API_URL + 'music.getall?view=default', {headers: {'x-access-token': token }})
        } else {
            return axios.get(API_URL + 'music.getall?view=author', {headers: {'x-access-token': token }})
        }
    }
}

const addMusic = (info, key) => {
    return axios.post(API_URL + 'music.add', {name: info.name, url: info.url, social_url: JSON.stringify(info.social), text: info.text, key: key}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
}

const login = (username, password) => {
    return axios.post(API_URL + 'user.login', {username: username, password: password}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});
}

const setStatus = (status, id) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'music.setstatus', {status: status, id: id}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const setlikes = (id, likes, dislikes) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'music.setlikes', {id: id, likes: likes, dislikes: dislikes}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const golike = (id, act) => {
    return axios.post(API_URL + 'music.golike', {id: id, act: act}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});
}

const getUrls = () => {
    return axios.get(API_URL + 'site.geturls');
}

const getNavLinks = () => {
    return axios.get(API_URL + 'site.getnavlinks');
}

const saveNavLinks = (str) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'site.savenavlinks', {str: str}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const saveUrls = (str) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'site.saveurls', {str: str}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const saveDop = (data) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'site.savedop', {data: data}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const saveContacts = (str) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'site.savecontacts', {str: str}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const adminRegistration = (username, password, token) => {
    return axios.post(API_URL + 'admin.registration', {username: username, password: password, token: token}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});
} 

const checkExist = () => {
    let token = localStorage.getItem('token');
    return axios.get(API_URL + 'admin.checkexist?token=' + token);
}

const adminEditMusic = (formData) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'music.musicedit', formData, {headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token }});
}

const adminAddMusic = (formData) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'music.musicadd', formData, {headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token }});
}

const adminGetMusic = () => {
    return axios.get(API_URL + 'music.musicget');
}

const adminDeleteMusic = (id) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'music.musicdelete', {id: id}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}


const adminEditVideo = (formData) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'video.videoedit', formData, {headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token }});
}

const adminAddVideo = (formData) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'video.videoadd', formData, {headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token }});
}

const adminGetVideo = () => {
    return axios.get(API_URL + 'video.videoget');
}

const adminDeleteVideo = (id) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'video.videodelete', {id: id}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const musicDownloadText = (id) => {
    let token = localStorage.getItem('token');
    return axios.get(API_URL + 'music.textdownload?id=' + id, {responseType: 'blob', headers: {'x-access-token': token }})
}

const eventGet = () => {
    return axios.get(API_URL + 'event.getall');
}

const eventAdd = (name, date, url) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'event.add', {name: name, date: date, ticket_url: url}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const eventEdit = (item, id) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'event.edit', {item: item, id: id}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const eventDelete = (id) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'event.delete', {id: id}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

const eventGetBio = () => {
    return axios.get(API_URL + 'event.getbio');
}

const eventSaveBio = (text) => {
    let token = localStorage.getItem('token');
    return axios.post(API_URL + 'event.savebio', {text: text}, {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token': token }});
}

export default {
    getMusic, addMusic, login, setStatus, setlikes, golike, getUrls, saveUrls, saveContacts, adminRegistration, checkExist, getNavLinks, saveNavLinks, 
    adminEditMusic, adminGetMusic, adminDeleteMusic, adminAddMusic, adminAddVideo, adminGetVideo, adminDeleteVideo, adminEditVideo, musicDownloadText,
    eventGet, eventAdd, eventEdit, eventDelete, eventGetBio, eventSaveBio, saveDop
}