<template>

    <div v-if="this.$route.path !== '/admin'">

        <div class="s">
            <Header />
            <router-view/>
        </div>
        <Footer />

    </div>

    <div v-else>
        <router-view/>
    </div>

    <notifications position="top right"/>

</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
    components: {
        Header, Footer
    },
    data() {
        return {

        }
    }
}
</script>

<style>
.s {
    min-height: calc(100vh - 170px);
}
</style>
